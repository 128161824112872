/* You can add global styles to this file, and also import other style files */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import '~mapbox-gl/dist/mapbox-gl.css';

.input-form {
    @apply shadow-sm;
    @apply block;
    @apply w-full;
    @layer sm:text;
    @apply border-bizdata-50;
    @apply rounded-md;
}

.input-form:focus {
    @apply ring-bizdata-500;
    @apply border-bizdata-50;
}

.checkbox-form {
    @apply h-4; 
    @apply w-4; 
    @apply text-bizdata-500; 
    @apply border-bizdata-50;
    @apply rounded;
}

.checkbox-form:focus {
    @apply ring-bizdata-500;
}

.button-form {
    @apply py-2; 
    @apply px-4; 
    @apply border;
    @apply rounded-md;
    @apply font-medium;
    @apply text-sm;
    @apply shadow-sm; 
}
.button-ok-form {
    @apply button-form;
    @apply ml-3; 
    @apply inline-flex; 
    @apply justify-center;  
    @apply border-transparent; 
    @apply text-white; 
    @apply bg-bizdata-500;
}

.button-ok-form:focus {
    @apply outline-none;
    @apply ring-2;
    @apply ring-offset-2;
    @apply ring-bizdata-500;
}

.button-nok-form {
    @apply button-form;
    @apply bg-white;
    @apply border-bizdata-50;  
    @apply text-bizdata-500;
}